import PageTitle from "../../components/pagetitle/PageTitle";

function News5() {
  return (
    <>
      <PageTitle
        motherMenu="Media Coverage"
        menuback="Previous Page"
        backbtn="/PageNewsList"
        activeMenu=""
      />

      <div className="mt-5">
        <section className="feature section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ml-auto justify-content-center">
                {/* <!-- About Mockup --> */}

                {/* <div className="image-content" data-aos="fade-right"> */}
                <img className="img-fluid" src="/images/team/news.jpg" alt="" />
                {/* </div> */}
              </div>
              <div className="col-lg-6 mr-auto align-self-center">
                <div className="feature-content">
                  {/* <!-- About Title --> */}

                  <h2>
                    <b>
                      YOUTH NATIONAL SCIENCE FAIR AND YOUTH INTERNATIONAL
                      SCIENCE FAIR 2022{" "}
                    </b>
                  </h2>

                  {/* <!-- About Description --> */}

                  <p className="desc">
                    Youth National Science Fair (YNSF) and Youth International
                    Science Fair (YISF) 2022 is one of the annual events
                    organized by IYSA. This year is the fourth year YNSF was
                    held and the second year for YISF. In 2021 YNSF and YISF are
                    included in the IYSA Festival series of events which are a
                    combination of several other events held together. Youth
                    National Science Fair is a national level project science
                    competition, which only participants from Indonesia can
                    participated. Meanwhile for Youth International Science Fair
                    is an international level project science competition where
                    the participants comes from various countries including
                    Indonesia.
                    <br />
                    <br />
                    This year YNSF received 42 participating teams which were
                    divided into 40 online teams and 2 offline teams, while the
                    number of teams for YISF participants is 490 teams which are
                    divided into 459 participating teams online from 22
                    countries and 31 participating teams offline. Several
                    countries participating in YISF competitions include Brazil,
                    Turkey, Serbia, Mexico, Thailand, Malaysia, Hong Kong,
                    Taiwan, South Korea, USA, United Arab Emirates, Romania,
                    Azerbaijan, India, Philippines, Puerto Rico, Kazakhstan,
                    Viet Nam, Macedonia, Sri Lanka, Russia, Indonesia.
                    <br />
                    <br />
                    Different from the previous year, this year's YISF and YNSF
                    Event conducted Hybrid (Online and Offline Competition).
                    YISF and YNSF offline events Was held in Dian Nuswantoro
                    University Building E 3-floor Semarang. This event was lasts
                    for 4 days, starting from March 14, for the Opening
                    Ceremony, March 15 is a Live Judging session for online and
                    offline participants, March 16, Inventors activity Talk and
                    Webinars, and March 17 for the Awarding Ceremony or closing
                    event.
                    <br />
                    <br />
                    Collaborate with one of the best universities in Indonesia,
                    namely the Department of Food Science and Technology-IPB and
                    Dian Nuswantoro University-Semarang and our supervision from
                    Turkey, namely BUCA IMSEF, we hope that the YNSF and YISF
                    activities this time can be one of the opprotunities for
                    students both in Indonesia and abroad to present their
                    creativity and innovation.
                    <br />
                    <br />
                    Penelitian sederhana tentang pupuk cair dengan bahan utama
                    kulit pisang, cangkang telur dan air cucian beras ini
                    dinilai sangat relevan dengan keadaan saat ini. Kualitas
                    pertanian di Indonesia bahkan di dunia saati ini mengalami
                    penurunan secara signifikan seiring dengan semakin tidak
                    produktifnya lahan pertanian akibat penggunaan pupuk kimia
                    dan kurangnya rehabilitasi tanah itu sendiri. menurut salah
                    satu tim KIR M. Rayyan Atthariq, penelitian ini bertujuan
                    untuk menghasilan pupuk organik yang mampu mengembalikan
                    kondisi tanah menjadi subur kembali karena ketiga bahan
                    tersebut telah dikenal memiliki kandungan nitrogen, phospar,
                    kalium dan karbon yang merupakan unsur hara yang dibutuhkan
                    oleh tanaman.
                    <br />
                    <br />
                    <b>We hope to see you in IYSA next event!</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default News5;
